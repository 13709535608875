<template>
  <div style="position: absolute;">
    <div style="width: 100%;border-radius: 0px 0px 20px 0px;
  background: linear-gradient(103deg, #7F3DD4 0%, #AC72FD 100%);height: 15%;">
      <img src="../../assets/images/roaclogo.png" alt="" style="margin-left: 5%;margin-top: 10%;" />
      <img src="../../assets/images/mingxi.png" alt="" style="width: 5%;margin-left: 58%;margin-top: 11.3%;"
        @click="routerlink3" />
      <img src="../../assets/images/msg.png" alt="" style="width: 5%;margin-left: 65%;" @click="showPopup" />
    </div>
    <div class="c2cbg">
      <div class="df" style="position: absolute;margin-top: -12%;width: 70%;">
        <div style="width: 100px;height:70px;margin-left: 13%;color: #1293D7;border-radius: 10px;background-color: #222325;
      border-color: #fff;">
          <img src="../../assets/images/transfer.png" alt="" style="width: 50px;height: 50px;margin-left: 14%;" />
          <span
            style="margin-left: -15%;position: absolute;margin-top: 15%;font-size: 0.4rem;font-weight: 0.7rem;color: #fff;">兑换</span>
        </div>
        <div
          style="width: 80px;height:60px;margin-left: 13%;color: #1293D7;border-radius: 10px;background-color: #E1EEFF;">
          <img src="../../assets/images/network.png" alt="" style="width: 40px;height: 40px;margin-left: 10%;" />
          <span
            style="margin-left: -11.5%;position: absolute;margin-top: 14%;font-size: 0.3rem;font-weight: 0.7rem;color: #000;">跨链</span>
        </div>
        <div
          style="font-size: 20px;width: 80px;height:60px;margin-left: 13%;color: #1293D7;border-radius: 10px;background-color: #E1EEFF;">
          <img src="../../assets/images/chain.png" alt="" style="width: 40px;height: 40px;margin-left: 11%;" />
          <span
            style="margin-left: -12%;position: absolute;margin-top: 14%;font-size: 0.3rem;font-weight: 0.7rem;color: #000;">行情</span>
        </div>

      </div>
    </div>
    <div class="detail">
      <div
        style="font-size: 15px;margin-left: 10%;width: 80%;height: 30%;padding-top: 7%;color: #000;font-weight: bold;">
        <img src="../../assets/images/volbg.png" style="width: 100%;height: 100%;" alt="" />
        <span style="position: absolute;margin-left: -63%;margin-top: 7%;">24H總成交量</span>
        <span style="position: absolute;margin-left: -63%;margin-top: 14%;">{{user.qw_day_chengjiao_num}}</span>
      </div>
      <div v-if="itemIndex == 1">

        <div style="padding: 20px;">

          <img src="../../assets/images/usdt.png"
            style="width: 8%;height: 3.8%;margin-top: 3%;margin-left: 1.6%;position: absolute;" alt="" />
          <img :class="{ active: itemIndex == 1 }" @click="btnClick(2)" src="../../assets/images/dh.png"
            style="width: 100%;height: 20%;" alt="" />
          <img src="../../assets/images/xg/LOGO.svg"
            style="width: 8%;height: 3.8%;margin-top: 3%;margin-left: -9.5%;position: absolute;" alt="" />
        </div>
        <div style="font-size: 0.4rem;margin-left: 36%;color: #000;">
          Roc實時價格
        </div>
        <div>
          <div style="margin-top: 5%;color: #000;font-weight: bold;">
            <input type="number" v-model="num"
              style="border-radius: 10px;width: 20%;margin-left: 10%;background-color: rgba(255, 255, 255, 0.30);border: #000000;height: 40px;font-size: 15px;" />
            <div>
              <div style="font-size: 15px;margin-left: 44%;margin-top: -8%;">
                {{price}}
              </div>
              <div style="font-size: 15px;margin-left: 73%;margin-top: -5%;">
                {{amount1.toFixed(2)}}
              </div>
            </div>
          </div>
          <div>
            <div @click="addOrder(num)" disabled
              style="background-color: #B791FA;width: 40%;border-radius: 10px;height: 40px;margin-left: 30%;margin-top: 8%;font-size: 20px;text-align: center;padding-top: 5%;color: #000;font-weight: bold;">
              立即兌換
            </div>
          </div>

        </div>
      </div>
      <div v-if="itemIndex == 2">
        <div style="padding: 20px;">

          <img src="../../assets/images/xg/LOGO.svg"
            style="width: 8%;height: 3.8%;margin-top: 3%;margin-left: 1.6%;position: absolute;" alt="" />
          <img :class="{ active: itemIndex == 2 }" @click="btnClick(1)" src="../../assets/images/dh.png"
            style="width: 100%;height: 20%;" alt="" />
          <img src="../../assets/images/usdt.png"
            style="width: 8%;height: 3.8%;margin-top: 3%;margin-left: -9.5%;position: absolute;" alt="" />
        </div>
        <div style="font-size: 0.4rem;margin-left: 36%;color: #000;">
          Roc實時價格
        </div>
        <div>
          <div style="margin-top: 5%;color: #000;font-weight: bold;">
            <input type="number" v-model="num"
              style="border-radius: 10px;width: 20%;margin-left: 10%;background-color: rgba(255, 255, 255, 0.30);border: #000000;height: 40px;font-size: 15px;" />
            <div>
              <div style="font-size: 15px;margin-left: 44%;margin-top: -8%;">
                {{price}}
              </div>
              <div style="font-size: 15px;margin-left: 73%;margin-top: -5%;">
                {{amount2.toFixed(2)}}
              </div>
            </div>
          </div>
          <div>
            <div @click="sellOrder(num)" disabled
              style="background-color: #B791FA;width: 40%;border-radius: 10px;height: 40px;margin-left: 30%;margin-top:8%;font-size: 20px;text-align: center;padding-top: 5%;color: #000;font-weight: bold;">
              立即兌換
            </div>
          </div>

        </div>
      </div>

    </div>

    <div
      style="border-radius: 10px;width: 90%;margin: auto;padding: 10px;margin-top: 10%;">
      <div style="font-size: 15px;width: 100%;display: flex;">
        <div style="width: 95%;">
          <img src="../../assets/images/xg/LOGO.svg" style="width: 30px;height: 30px;margin-left: 5%;" alt="" />
          <div style="color: aliceblue;margin-top: -8%;margin-left: 20%;color: #000;font-weight: bold;">{{ nubsub }}
            <br /><van-rate color="#8A52F9" v-model="user.level" readonly />
            <img src="../../assets/images/faq.png" style="margin-left: 55%;margin-top: -5%;width: 10%;height: 10%;"
              @click="showPopup" alt="" />
          </div>
        </div>
      </div>
      <div style="height: 230px;">
        <div style="font-size: 13px;width: 100%;margin-top:3%;">
          <div class="df" style="width: 95%;margin-left: 2.5%;">
            <div
              style="display: flex;margin-top: 3%;width: 60%;height: 50px;border-radius: 10px;">
              <div>
                <img src="../../assets/images/xg/LOGO.svg"
                  style="width: 20px;height: 20px;margin-left: 70%;margin-top: 30%;" alt="" />
              </div>
              <div style="margin-left: 13%;color: #000;width: 15%;margin-top: 6%;">
                ROC
              </div>
              <div style="margin-left: -15%;color: #000;margin-top: 18%;">
                {{balance.personal_money}}
              </div>
            </div>

            <div
              style="display: flex;margin-top: 3%;width: 60%;height: 50px;border-radius: 10px;margin-left: 15%;">
              <div>
                <img src="../../assets/images/usdt.png"
                  style="width: 20px;height: 20px;margin-left: 90%;margin-top: 30%;" alt="" />
              </div>
              <div style="margin-left: 18%;color: #000;width: 15%;margin-top: 6%;">
                USDT
              </div>
              <div style="margin-left: -15%;color: #000;margin-top: 18%;">
                {{balance.money}}
              </div>
            </div>
          </div>
          <div style="display: flex;margin-top: 3%;">
            <div>
              <img src="../../assets/images/zl.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
            </div>
            <div style="margin-left: 18%;color: #000;width: 15%;">
              總量
            </div>
            <div style="margin-left: 32%;color: #000;">
              {{user.chengjiao_num}}
            </div>
          </div>
          <div style="display: flex;margin-top: 3%;">
            <div>
              <img src="../../assets/images/cj.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
            </div>
            <div style="margin-left: 18%;color: #000;width: 15%;">
              已成交
            </div>
            <div style="margin-left: 32%;color: #000;">
              {{user.chengjiao_num}}
            </div>
          </div>
          <div style="display: flex;margin-top: 3%;">
            <div>
              <img src="../../assets/images/je.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
            </div>
            <div style="margin-left: 18%;color: #000;width: 15%;">
              總金額
            </div>
            <div style="margin-left: 32%;color: #000;">
              {{user.total_price}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!--FAQ彈框開始 -->
  <div v-if="show" style="margin-top: 50%;position: absolute;margin-left: 15%;width: 100%;">
    <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;" />
    <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -73%;margin-left: 5%;">
      1.兌換貨幣：支持多主流幣種相互兌換。<br />
      2.手續費：兌換手續費為0.8%雙向收取。<br />
      3.兌換轉換：提供多種數字貨幣之間的兌換選擇，用戶可以根據需求選擇各種數字貨幣之間兌換轉換。<br />
      4.交易執行：鯤鵬DEX融合了dydx協議，運用混合兌換模式，具有兌換執行速度快、無滑點、低費用、無需信任等特點，用戶可以隨時兌換數字貨幣。
    </div>
    <div @click="closePopup"
      style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 23%;margin-top: -11%;position: absolute;">
      已知悉</div>
  </div>
  <!--FAQ彈框開始 -->

</template>

<script>
  import {
    getTradeOrder,
    setTradeOrder,
    getRocPrice,
    getTradeOtherInfo
  } from "@/utils/api.js";
  import {
    Popup,
    Button,
    radio,
    Locale
  } from 'vant';
  import {
    getUserInfo
  } from "../../utils/api";
  export default {
    data() {
      return {
        itemIndex: 1,
        list: [],
        selllist: [],
        show: false,
        type: '1',
        price: '',
        num: '',
        amount1: 0,
        amount2: 0,
        user: [],
        balance: [],
        nubsub: '',
      }
    },
    watch: {
      num: function(newValue) {
        this.amount1 = newValue / this.price || 0;
        this.amount2 = newValue * this.price || 0;
      },
    },
    created() {
      this.getlist()
      this.getrocjiage()
      this.getuser()
      this.getuserbalance()
    },
    methods: {
      qiehuan(id) {
        this.type = String(id)
      },
      routerlink3() {
        this.$router.push("/user/c2cDeltail");
      },
      goTo(to) {
        this.$router.push(to);
      },
      addOrder(num) {
        let params = {
          roc_price: this.price,
          num: this.num,
          type: 1,
        }
        setTradeOrder(params).then((res) => {
          if (res.code == 200) {
            this.$toast("提交成功");
            location.reload();
          }
        })
      },
      closePopup() {
        this.show = false;
      },
      showPopup() {
        this.show = true;
      },
      sellOrder(num) {
        let params = {
          roc_price: this.price,
          num: this.num,
          type: 2,
        }
        setTradeOrder(params).then((res) => {
          if (res.code == 200) {
            this.$toast("提交成功");
          }
        })
      },
      btnClick(index) {
        this.itemIndex = index
      },
      onClickRight() {
        this.show = true;
      },
      clousePop() {
        this.show = false;
      },
      buy(item) {
        toBuy(item).then((res) => {
          if (res.code == 200) {
            this.$toast("提交成功");
          }
        })
        console.log('submit', item);
      },
      sell(item) {
        toSell(item).then((res) => {
          if (res.code == 200) {
            this.$toast("提交成功");
          }
        })
        console.log('submit', item);
      },
      getrocjiage() {
        getRocPrice().then((res) => {

          this.price = res.data.roc_price
        })
      },
      getuserbalance() {
        getUserInfo().then((res) => {

          this.balance = res.data.user
          this.nubsub = res.data.user.dapp_address.substring(0, 3) + "****" + res.data.user.dapp_address.substr(res
            .data.user
            .dapp_address.length - 4)
        })
      },
      getuser() {
        getTradeOtherInfo().then((res) => {

          this.user = res.data
        })
      },
      getlist() {
        let params = {
          page: 1,
          pagesize: 20
        }
        getTradeOrder(params).then((res) => {
          this.list = res.data.list
        })
      }
    }
  };
</script>

<style lang="scss">
  .detail {
    background-color: rgba(172, 118, 250, 0.15);
    // background-color: rgb(30, 35, 41);
    padding-top: 5%;
    width: 85%;

    margin-top: 6%;
    margin-left: 8%;
    border-radius: 10px;
    color: #fff;

  }

  .c2cbg {
    // background-color: #181a20;
    // height: 100%;
    width: 100%;
    touch-action: pan-y;

    .tab {
      display: flex;

      .btn {

        margin-left: 5%;
        font-size: 50px;
      }
    }
  }

  .active {
    font-weight: bold;
    color: #3E74EE;
    font-size: 36px;
  }
</style>
